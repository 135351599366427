import React, { useState, useEffect } from "react";

import {
    Button, Container, Form, Grid, Header, Input, Modal, Table, Icon,
} from 'semantic-ui-react';
import ProfileImg from "../../ui-component/profileImg";
import { useNavigate } from 'react-router-dom'
import EmployeeService from "../../services/employeeServices";
import UserService from "../../services/userservices";
import { toast } from "react-toastify";
import FormValidation from "../../common/FormValidation";


function exampleReducer(state: any, action: any) {
    switch (action.type) {
        case 'close':
            return { open: false }
        case 'open':
            return { open: true, size: action.size }
        default:
            throw new Error('Unsupported action...')
    }
}

interface IUser {
    FirstName: string,
    LastName: string
    Address: string,
    Email: string,
    PhoneNumber: string
}

interface IChangePassword {
    userId?: number,
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
}
const DefaultChangePassword: IChangePassword = {
    userId: 0,
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
}

function MyProfile() {
    const [formErrors, setFormErrors] = useState<any>({});
    const [userDetails, setUserDetails] = useState<IUser>();
    const [activeItem, setActive] = useState('');
    const [userId, setUserId] = useState<number>();
    const [changePassForm, setChangePassForm] = useState<IChangePassword>(DefaultChangePassword)
    const navigate = useNavigate()

    useEffect(() => {
        const userDetail = JSON.parse(localStorage.getItem("UserDetails") || '');
        getEmployeeByEmployeeCode(userDetail.employeeCode);
        setUserId(userDetail.userId);
        setChangePassForm({
            ...changePassForm,
            userId: userDetail.userId
        })
    }, [])

    useEffect(() => {
        if (activeItem) {
            navigate(`/${activeItem}`)
        }
    }, [activeItem])

    const [state, dispatch] = React.useReducer(exampleReducer, {
        open: false,
        size: undefined,
    })
    const { open, size } = state;

    const getEmployeeByEmployeeCode = (employeeCode: any) => {
        EmployeeService.getEmployeeByEmployeeCode(employeeCode).then((res: any) => {
            if (res && res.data) {
                setUserDetails(res.data);
                localStorage.setItem('ProfileData', JSON.stringify(res.data));
            }
        })
    }

    const changePassword = (e: any) => {
        e.preventDefault();
        const errors = FormValidation(changePassForm);
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            UserService.ChangePassword({ ...changePassForm }).then((res: any) => {
                if (res && res.data && res.data.flag !== 0) {
                    toast.success(res.data.msg);
                }
                else {
                    toast.error(res.data.msg);
                }
                dispatch({ type: 'close' });
            }).catch(() => {
                toast.error('Something went wrong!');
                dispatch({ type: 'close' });
            })
            resetForm(e);
        }
    }

    const changeHandle = (e: any) => {
        setChangePassForm({
            ...changePassForm,
            [e.target.name]: e.target.value
        })
        if (e.target.value.trim() !== '') {
            //setFormErrors({ ...formErrors, [e.target.name]: '' })
            delete formErrors[e.target.name]
            setFormErrors({ ...formErrors })
            console.log({ ...formErrors });
        } else {
            setFormErrors({ ...formErrors, [e.target.name]: ' is required!' })
        }
    }

    const resetForm = (e: any) => {
        e.preventDefault()
        DefaultChangePassword.userId = userId;
        setChangePassForm(DefaultChangePassword);
    }

    const [oldpasswordType, setPasswordType] = useState("password");
    const [newpasswordType, setNewPasswordType] = useState("text");
    const [confirmpasswordType, setConfirmPasswordType] = useState("password");
    const toggleOldPassword = () => {
        if (oldpasswordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const toggleNewPassword = () => {
        if (newpasswordType === "password") {
            setNewPasswordType("text")
            return;
        }
        setNewPasswordType("password")
    }
    const toggleConfirmPassword = () => {
        if (confirmpasswordType === "password") {
            setConfirmPasswordType("text")
            return;
        }
        setConfirmPasswordType("password")
    }

    return (
        <Container className="pt40">
            <Grid  >
                <Grid.Column width={4} className="profileBG" >
                    <ProfileImg widthImg={150} />

                    <Header as='h3' className="name" textAlign='center'>Jake Smith</Header>
                    <Header as='h5' className="name" textAlign='center'>Designer</Header>

                    <Button inverted className="mt20" onClick={() => dispatch({ type: 'open', size: 'tiny' })}>
                        Change Password
                    </Button>

                    <Modal
                        size={size}
                        open={open}
                        onClose={() => dispatch({ type: 'close' })}
                    >
                        <Modal.Header>Change Your Password</Modal.Header>
                        <Modal.Content>
                            <Form>
                                <Form.Field
                                    control={Input}
                                    type={oldpasswordType}
                                    name='oldPassword'
                                    value={changePassForm?.oldPassword}
                                    label='Current Password'
                                    onChange={changeHandle}
                                    onBlur={changeHandle}
                                    placeholder='Current Password'
                                    required
                                    error={formErrors?.oldPassword && 'Current Password' + formErrors?.oldPassword}
                                    icon={<Icon onClick={toggleOldPassword} name={oldpasswordType === "password" ? 'eye slash' : 'eye'} link />}
                                />
                                <Form.Field
                                    control={Input}
                                    type={newpasswordType}
                                    name='newPassword'
                                    value={changePassForm?.newPassword}
                                    label='New Password'
                                    onChange={changeHandle}
                                    onBlur={changeHandle}
                                    placeholder='New Password'
                                    error={formErrors?.newPassword && 'New Password' + formErrors?.newPassword}
                                    required
                                    icon={<Icon onClick={toggleNewPassword} name={newpasswordType === "password" ? 'eye slash' : 'eye'} link />}
                                />
                                <Form.Field
                                    control={Input}
                                    type={confirmpasswordType}
                                    name='confirmPassword'
                                    value={changePassForm?.confirmPassword}
                                    label='Re-enter New Password'
                                    onChange={changeHandle}
                                    onBlur={changeHandle}
                                    placeholder='Re-enter New Password'
                                    error={formErrors?.confirmPassword && 'Confirm Password' + formErrors?.confirmPassword}
                                    required
                                    icon={<Icon onClick={toggleConfirmPassword} name={confirmpasswordType === "password" ? 'eye slash' : 'eye'} link />}
                                />
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={resetForm}>
                                Reset
                            </Button>
                            <Button positive onClick={changePassword}>
                                Save
                            </Button>
                        </Modal.Actions>
                    </Modal>

                </Grid.Column>
                <Grid.Column width={12} className="bdr">
                    <Grid columns='equal'>
                        <Grid.Column>
                            <Header as='h3' >Profile Details</Header>
                        </Grid.Column>
                        <Grid.Column width={8} textAlign="right">
                            <Button primary onClick={() => setActive('profileEdit')}>
                                Edit Profile
                            </Button>
                        </Grid.Column>

                    </Grid>




                    <Table definition>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={5}>Full Name</Table.Cell>
                                <Table.Cell>{userDetails?.FirstName} {userDetails?.LastName}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Email</Table.Cell>
                                <Table.Cell>{userDetails?.Email}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Mobile</Table.Cell>
                                <Table.Cell>{userDetails?.PhoneNumber}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Address</Table.Cell>
                                <Table.Cell>{userDetails?.Address}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid>
        </Container >
    );
}

export default MyProfile;
