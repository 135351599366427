// material-ui

import profileImg from "../images/ava3.webp";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const ProfileImg = (props) => {


    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */
         <img src={profileImg} alt="profile" className='profile-pic' style={{width: props.widthImg + 'px'}} avatar />
    );
};

export default ProfileImg;
