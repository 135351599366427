import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as MdIcons from 'react-icons/md';

export const SidebarData = [
  {
    title: 'Users',
    path: '/users',
    icon: <AiIcons.AiFillHome />,
    cName: 'nav-text'
  },
  {
    title: 'Employees',
    path: '/employees',
    icon: <IoIcons.IoIosPaper />,
    cName: 'nav-text'
  },
  {
    title: 'Departments',
    path: '/departments',
    icon: <FaIcons.FaCartPlus />,
    cName: 'nav-text'
  },
  {
    title: 'Books',
    path: '/books',
    icon: <MdIcons.MdLibraryBooks />,
    cName: 'nav-text'
  },
  {
    title: 'Books List',
    path: '/BooksList',
    icon: <MdIcons.MdLibraryBooks />,
    cName: 'nav-text'
  },
  {
    title: 'Books Report',
    path: '/BooksReport',
    icon: <MdIcons.MdLibraryBooks />,
    cName: 'nav-text'
  },
  {
    title: 'My Profile',
    path: '/myProfile',
    icon: <MdIcons.MdLibraryBooks />,
    cName: 'nav-text'
  }
  // {
  //   title: 'Team',
  //   path: '/team',
  //   icon: <IoIcons.IoMdPeople />,
  //   cName: 'nav-text'
  // },
  // {
  //   title: 'Messages',
  //   path: '/messages',
  //   icon: <FaIcons.FaEnvelopeOpenText />,
  //   cName: 'nav-text'
  // },
  // {
  //   title: 'Support',
  //   path: '/support',
  //   icon: <IoIcons.IoMdHelpCircle />,
  //   cName: 'nav-text'
  // }
];
