import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Container, Grid, Header, Icon, Segment } from "semantic-ui-react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DepartmentService from "../../services/departmentServices";
import Departments from "./Departments";

interface IDepartmentForm {
  ID?: number;
  Code: string;
  Name: string;
  IsDelete?: boolean;
}

const defaultDepartmentFormValue =
{
  Code: '',
  Name: '',
  IsDeleted: false
}

interface Iprops {
  departmentDetails: IDepartmentForm
}

function AddDepartments(props: Iprops) {
  const navigate = useNavigate()
  const [form, setForm] = useState<IDepartmentForm>(defaultDepartmentFormValue);

  const [isUpdateFlag, isUpdate] = useState(false);

  useEffect(() => {
    console.log(props.departmentDetails);
    if (props.departmentDetails !== null && Object.keys(props.departmentDetails).length > 0) {
      setForm(props.departmentDetails);
      isUpdate(true);
    }
  }, [props.departmentDetails]);

  const OnChangDepartmentCode = (event: any) => {
    setForm({
      ...form,
      Code: event.target.value,
    });
  };

  const OnChangDepartmentName = (event: any) => {
    setForm({
      ...form,
      Name: event.target.value,
    });
  };


  const onSubmitForm = () => {
    console.log("form", form);
    if (isUpdateFlag) {
      updateDepartment()
    } else {
      CreateDepartment()
    }
  }

  const CreateDepartment = () => {
    DepartmentService.CreateDepartment(form).then((response: any) => {
      console.log(response.data);
      if (response.data !== null && response.data !== undefined && response.data.userId !== 0) {
        toast.success('Department crated successfully!');
        setTimeout(() => {
          navigate('/Departments');
        }, 2000);
      }
      else {
        toast.error('Something went wrong!');
      }
    }).catch(() => {
      toast.error('Something went wrong!');
    })
  }

  const updateDepartment = () => {
    DepartmentService.updateDepartment(form).then((response: any) => {
      console.log(response.data);
      if (response.data !== null && response.data !== undefined && response.data.userId !== 0) {
        toast.success('Department Updated successfully!');
        setTimeout(() => {
          navigate('/Departments');
        }, 2000);
      }
      else {
        toast.error('Something went wrong!');
      }
    }).catch(() => {
      toast.error('Something went wrong!');
    })
  }

  return (
    <Container>
      <Grid columns={1}>
        <Grid.Column>

          <Grid className="pt20">
            <Grid.Column width={6}>
              <div className="vmid">


                <Link to={"/departments"} className="backbtn">
                  <Icon name='arrow left' />
                </Link>
                <Header as='h2' className="mt0 pl15"  >Add Departments</Header>
              </div>

            </Grid.Column>
            <Grid.Column textAlign='right' floated='right'  >

            </Grid.Column>
          </Grid>

        </Grid.Column>
        <Grid columns={1}>
          <Segment>
            <Form>
              <Form.Group widths="equal">
                <Form.Field className='col-form'>
                  <label className='label'>Department Code</label>
                  <input className='form-control'
                    type='text' name="departmentCode" value={form.Code} disabled={isUpdateFlag && form.Code.length>0?true:false}
                    onChange={OnChangDepartmentCode}
                    placeholder='Department Code' />
                </Form.Field>
                <Form.Field className='col-form'>
                  <label className='label'>Department Name</label>
                  <input className='form-control'
                    type='text' name="departmentName" value={form.Name}
                    onChange={OnChangDepartmentName}
                    placeholder='Department name' />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
              </Form.Group>
              <Form.Group inline>
                <Form.Button primary onClick={onSubmitForm}>Submit</Form.Button>
                <Form.Button  >Reset</Form.Button>
              </Form.Group>
            </Form>
          </Segment>
        </Grid>
      </Grid>
    </Container>
  );
}
export default AddDepartments;
