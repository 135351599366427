import http from "../common/http-common";

const createEmployee = (param: any) => {
  return http.post("/Employees/CreateEmployee", param);
};

const updateEmployee = (param: any) => {
  return http.post("/Employees/UpdateEmployee", param);
};
const updateEmployeeProfile = (param: any) => {
  return http.post("/Employees/UpdateEmployeeProfile", param);
};

const deleteEmployee = (param: any) => {
  return http.post("/Employees/DeleteEmployee", param);
};

const getAllEmployees = (param: any) => {
  return http.post("/Employees/GetAllEmployees", param);
};

const getEmployeeByEmployeeCode = (employeeCode:string) => {
  employeeCode = employeeCode.replace('#','%23');
  return http.get(`/Employees/GetEmployeeByEmployeeCode?employeeCode=${employeeCode}`);
};

const EmployeeService = {
  createEmployee,
  getAllEmployees,
  deleteEmployee,
  updateEmployee,
  updateEmployeeProfile,
  getEmployeeByEmployeeCode
}

export default EmployeeService;