import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { DataGrid, GridColDef, GridValueGetterParams, GridApi, GridToolbar, GridRenderCellParams, gridClasses } from '@mui/x-data-grid';
import {
  Grid,
  Segment,
  Header,
  Container,
  Menu, Icon, Label, Button, Input, Modal
} from 'semantic-ui-react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import UserService from "../../services/userservices";

interface IUserModal {
  UserId?: number,
  UserName: string,
  Password: string,
  EmployeeCode?: string,
  IsDeleted: boolean
}


function Users(props: any) {

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 15,
    page: 0,
  });
  const [previousPgSize, setPreviousPgSize] = useState(paginationModel.pageSize)
  const [data, setData] = useState({
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [5, 10, 15]
  });

  const updateData = (k: string, v: any) => setData((prev) => ({ ...prev, [k]: v }));

  useEffect(() => {
    if (previousPgSize !== paginationModel.pageSize) {
      setPaginationModel({
        ...paginationModel,
        page: 0
      })
      setPreviousPgSize(paginationModel.pageSize);
    }
    const param = {
      pageNumber: paginationModel.page + 1,
      pageSize: paginationModel.pageSize
    }
    getAllusers(param);
  }, [paginationModel.page, paginationModel.pageSize]);

  const { id, value, field } = props;
  const [userDetail, setUsersDetails] = useState([]);
  const [open, setOpen] = React.useState(false);
  const columns: GridColDef[] = [
    { field: 'UserId', headerName: 'User Id', width: 100, },
    { field: 'UserName', headerName: 'User name', width: 150, flex: 1 },
    {
      field: 'action',
      type: 'actions',
      headerName: 'Action',
      width: 200,
      cellClassName: 'actions',

      renderCell: (params) => {

        const handleClickOpen = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking
          console.log(params.row)
          setOpen(true);
          const api: GridApi = params.api;
        };
        const handleEdit = (e: any) => {
          e.preventDefault();
          console.log(params.row);
          props.setOriginalUserData(params.row);
        }

        return (
          <div>
            <Button.Group>
              <Button icon='edit' basic color='blue' onClick={handleEdit} />
              <Button icon='trash' basic color='red' onClick={handleClickOpen} />
            </Button.Group>

          </div>
        );
      }
    },
  ];


  const [activeItem, setActive] = useState('')
  const navigate = useNavigate()
  useEffect(() => {
    if (activeItem) {
      props.setOriginalUserData(null);
      navigate(`/${activeItem}`)
    }
  }, [activeItem])

  const getAllusers = (param: any) => {
    UserService.GetAllUsers(param).then((response: any) => {
      if (response && response.data && response.data.length > 0) {
        setUsersDetails(response.data);
        const rows = response.data
        console.log(rows);
        updateData("rows", rows);
        updateData("totalRows", response.data[0].Total);
      }
      console.log(response.data);
    })
  }

  const handleClose = () => {
    setOpen(false);
  };


  return (


    <Container fluid>
      <Grid columns={1}>
        <Grid.Column>

          <Grid className="pt20">
            <Grid.Column floated='left' width={6}>
              <Header as='h2'  >Users</Header>
            </Grid.Column>
            <Grid.Column textAlign='right' floated='right' width={6}>
              <Button
                floated='right'
                icon
                labelPosition='left'
                primary
                size='small'
                onClick={() => setActive('adduser')}
              >
                <Icon name='user' /> Add Users
              </Button>
            </Grid.Column>
          </Grid>




          {/* <Grid  >
              <Grid.Column textAlign='right' >
                <Input action={{ icon: 'search' }} placeholder='Search...' />
              </Grid.Column>

            </Grid> */}

          <div style={{ height: 590, width: '100%', marginTop: '20px' }}>
            <DataGrid
              getRowHeight={() => 'auto'}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 1,
                },
              }}
              slots={{ toolbar: GridToolbar }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              className="customInput"
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              disableRowSelectionOnClick
              rows={data.rows}
              rowCount={data.totalRows}
              getRowId={(r) => r.UserId}
              columns={columns}
              pageSizeOptions={data.rowsPerPageOptions}
              paginationModel={paginationModel}
              paginationMode="server"
              onPaginationModelChange={setPaginationModel} />
          </div>



        </Grid.Column>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Your Account"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete your account
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button negative onClick={handleClose}>No</Button>
          <Button positive onClick={handleClose} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    </Container>



  );
}
export default Users;
