import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Container, Grid, Header, Icon, Segment } from "semantic-ui-react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BookService from "../../services/bookServices";
import DepartmentService from "../../services/departmentServices";

interface IBookForm {
  Bookserialno?: number;
  Bookcode: string;
  Bookname: string;
  Bookauthor: string;
  Bookvolume: string;
  Edition: string;
  Bookyear: string;
  Remark1: string;
  Remark2: string;
  Remark3: string;
  DepartmentCode: string;
  IsDelete?: boolean;
}

const defaultBookFormValue =
{
  Bookcode: '',
  Bookname: '',
  Bookauthor: '',
  Bookvolume: '',
  Edition: '',
  Bookyear: '',
  Remark1: '',
  Remark2: '',
  Remark3: '',
  DepartmentCode: 'IT',
  IsDeleted: false
}


function AddBook(props: any) {

  const [isUpdateFlag, isUpdate] = useState(false);
  useEffect(() => {
    console.log(props.bookDetails);
    if (props.bookDetails !== null && Object.keys(props.bookDetails).length > 0) {
      props.bookDetails.DepartmentCode = props.bookDetails.DepartmentCode !== null ? props.bookDetails.DepartmentCode : 'IT';
      props.bookDetails.Remark1 = props.bookDetails.Remark1 !== null ? props.bookDetails.Remark1 : '';
      props.bookDetails.Remark2 = props.bookDetails.Remark2 !== null ? props.bookDetails.Remark2 : '';
      props.bookDetails.Remark3 = props.bookDetails.Remark3 !== null ? props.bookDetails.Remark3 : '';

      setForm(props.bookDetails);
      isUpdate(true);
    }
    getAllDepartments();
  }, [props.bookDetails])

  const navigate = useNavigate()
  const [form, setForm] = useState<IBookForm>(defaultBookFormValue);
  const [departments, setDepartment] = useState([]);
  const OnChangeBookcode = (event: any) => {
    setForm({
      ...form,
      Bookcode: event.target.value,
    });
  };

  const OnChangeBookname = (event: any) => {
    setForm({
      ...form,
      Bookname: event.target.value,
    });
  };

  const OnChangeBookauthor = (event: any) => {
    setForm({
      ...form,
      Bookauthor: event.target.value,
    });
  };

  const OnChangeDepartment = (event: any) => {
    setForm({
      ...form,
      DepartmentCode: event.target.value,
    });
  };

  const OnChangeBookvolume = (event: any) => {
    setForm({
      ...form,
      Bookvolume: event.target.value,
    });
  };
  const OnChangeEdition = (event: any) => {
    setForm({
      ...form,
      Edition: event.target.value,
    });
  };

  const OnChangeBookyear = (event: any) => {
    setForm({
      ...form,
      Bookyear: event.target.value,
    });
  };
  const OnChangeRemarks1 = (event: any) => {
    setForm({
      ...form,
      Remark1: event.target.value,
    });
  };

  const OnChangeRemarks2 = (event: any) => {
    setForm({
      ...form,
      Remark2: event.target.value,
    });
  };
  const OnChangeRemarks3 = (event: any) => {
    setForm({
      ...form,
      Remark3: event.target.value,
    });
  };

  const getAllDepartments = () => {
    const param = {
      pageSize: 100,
      pageNumber: 1
    };
    DepartmentService.getAllDepartments(param).then((response: any) => {
      if (response && response.data && response.data.length > 0)
        setDepartment(response.data);
    })
  }

  const onSubmitForm = () => {
    console.log("form", form);
    if (isUpdateFlag) {
      updateBookDetails()
    } else {
      createBook()
    }
  }

  const createBook = () => {
    BookService.CreateBook(form).then((response: any) => {
      console.log(response.data);
      if (response.data !== null && response.data !== undefined && response.data.Bookserialno !== 0) {
        toast.success('Book saved successfully!');
        setTimeout(() => {
          navigate('/Books');
        }, 2000);
      }
      else {
        toast.error('Something went wrong!');
      }
    }).catch(() => {
      toast.error('Something went wrong!');
    })
  }

  const updateBookDetails = () => {
    BookService.UpdateBook(form).then((response: any) => {
      console.log(response.data);
      if (response.data !== null && response.data !== undefined && response.data.Bookserialno !== 0) {
        toast.success('Book Updated successfully!');
        setTimeout(() => {
          navigate('/Books');
        }, 2000);
      }
      else {
        toast.error('Something went wrong!');
      }
    }).catch(() => {
      toast.error('Something went wrong!');
    })
  }

  return (
    <Container>
      <Grid columns={1}>
        <Grid.Column>

          <Grid className="pt20">
            <Grid.Column width={6}>
              <div className="vmid">


                <Link to={"/books"} className="backbtn">
                  <Icon name='arrow left' />
                </Link>
                <Header as='h2' className="mt0 pl15"  >Add Book</Header>
              </div>

            </Grid.Column>
            <Grid.Column textAlign='right' floated='right'  >

            </Grid.Column>
          </Grid>

        </Grid.Column>
        <Grid columns={1}>
          <Segment>
            <Form>
              <Form.Group widths="equal">
                <Form.Field className='col-form' required>
                  <label className='label'>Book Code</label>
                  <input className='form-control'
                    type='text' name="BookCode" value={form.Bookcode} maxLength={255}
                    placeholder='Book Code' onChange={OnChangeBookcode} />
                </Form.Field>
                <Form.Field className='col-form' required>
                  <label className='label'>Book Title</label>
                  <input className='form-control'
                    type='text' name="BookTitle" value={form.Bookname} maxLength={250}
                    placeholder='Book Title' onChange={OnChangeBookname} />
                </Form.Field>
                <Form.Field className='col-form' required>
                  <label className='label'>Book Author</label>
                  <input className='form-control'
                    type='text' name="BookAuthor" value={form.Bookauthor} maxLength={100}
                    placeholder='Book Author' onChange={OnChangeBookauthor} />
                </Form.Field>
                <Form.Field className='col-form'>
                  <label className='label'>Department</label>
                  <select className='form-select' value={form.DepartmentCode} name="DepartmentCode"
                    onChange={OnChangeDepartment}>
                    {departments.map((data: any) =>
                      <option value={data.Code} key={data.ID}>{data.Name}</option>
                    )}
                  </select>
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field className='col-form'>
                  <label className='label'>Book Edition</label>
                  <input className='form-control'
                    type='text' name="BookEdition" value={form.Edition} maxLength={25}
                    placeholder='Book Edition' onChange={OnChangeEdition} />
                </Form.Field>
                <Form.Field className='col-form'>
                  <label className='label'>Book Volume</label>
                  <input className='form-control'
                    type='text' name="BookVolume" value={form.Bookvolume} maxLength={10}
                    placeholder='Book Volume' onChange={OnChangeBookvolume} />
                </Form.Field>
                <Form.Field className='col-form'>
                  <label className='label'>Book Year</label>
                  <input className='form-control'
                    type='number' name="lastName" value={form.Bookyear} max={3000} min={1}
                    placeholder='Book Year' onChange={OnChangeBookyear} />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">


                <Form.Field className='col-form'>
                  <label className='label'>Remarks 1</label>
                  <input className='form-control'
                    type='text' name="Remarksa" value={form.Remark1} maxLength={50}
                    placeholder='Remarks 1' onChange={OnChangeRemarks1} />
                </Form.Field>
                <Form.Field className='col-form'>
                  <label className='label'>Remarks 2</label>
                  <input className='form-control'
                    type='text' name="Remarksb" value={form.Remark2} maxLength={50}
                    placeholder='Remarks 2' onChange={OnChangeRemarks2} />
                </Form.Field>
                <Form.Field className='col-form'>
                  <label className='label'>Remarks 3</label>
                  <input className='form-control'
                    type='text' name="Remarksb" value={form.Remark3} maxLength={50}
                    placeholder='Remarks 3' onChange={OnChangeRemarks3} />
                </Form.Field>


              </Form.Group>
              <Form.Group widths="equal">
              </Form.Group>
              <Form.Group inline>
                <Form.Button primary onClick={onSubmitForm}>Submit</Form.Button>
                <Form.Button  >Reset</Form.Button>
              </Form.Group>
            </Form>
          </Segment>
        </Grid>
      </Grid>
    </Container>
  );
}
export default AddBook;
