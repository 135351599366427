import React, { useState, useEffect } from "react";

import {
  Grid,
  Segment,
  Header,
  Container,
  Button,
  Icon,
  Card, Form, Input, Feed, Search
} from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom'
import BookService from "../../services/bookServices";
import EmployeeService from "../../services/employeeServices";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { data } from "jquery";
import { toast } from "react-toastify";
import { debug } from "console";


interface IBooksModal {
  Bookauthor: string,
  Bookcode: string,
  Bookname: string,
  Bookserialno?: number | null,
  Bookvolume?: string,
  Bookyear?: number | null,
  DepartmentCode?: string,
  Edition?: string,
  IsDeleted: boolean,
  Remark1?: string,
  Remark2?: string,
  Remark3?: string,
  AllocatedTo?: number | null,
  IsAllocated?: boolean | null
}

interface IUserModal {
  userId?: number;
  userName: string;
  Password: string;
  IsDelete?: boolean;
  employeeCode?: string;
  employeeName?: string;
  departmentCode?: string;
  departmentName?: string;
}

const defaultBookData = {
  Bookserialno: null,
  AllocatedTo: 1,
  IsAllocated: null,
}



function Books(props: any) {
  const [open, setOpen] = React.useState(false);
  const [booksDetail, setBooksDetail] = useState<IBooksModal[]>([]);
  const [topBooksDetails, setTopBooksDetail] = useState<IBooksModal[]>([]);
  const [allBookDetails, setAllBookDetails] = useState<IBooksModal[]>([]);
  const [bookserialNo, setSelectedBookSerialNo] = useState(0);
  const [employeeDetail, setEmployeesDetail] = useState([]);
  const [bookForm, setBookForm] = useState(defaultBookData);
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [userDetails, setUsersDetails] = useState<IUserModal>();
  const [searchOn, setSearchOn] = useState('Bookcode');
  type ObjectKey = keyof IBooksModal;

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    // stringify: (option:IBooksModal) => option[searchOn as ObjectKey],
  });


  function handleFieldData(value: any, name: string, index: number) {
    setBookForm({ ...bookForm, [name]: value });
    booksDetail[index].AllocatedTo = value;
  }


  const addBookCalled = ((activeItem: any) => {
    if (activeItem) {
      navigate(`/${activeItem}`)
    }
  });

  const getAllBooks = () => {
    BookService.getAllBooks().then((response: any) => {
      if (response && response.data && response.data.length > 0) {
        console.log(response.data);
        response.data.forEach((bookData: IBooksModal) => {
          bookData.AllocatedTo = bookData.AllocatedTo ? bookData.AllocatedTo : 1;
        });
        // setBooksDetail(response.data);
        setAllBookDetails(response.data);
      }
    })
  }

  const getAllBooksByDepartment = (param: any) => {
    BookService.getAllBooksByDepartment(param).then((response: any) => {
      if (response && response.data && response.data.length > 0) {
        console.log(response.data);
        response.data.forEach((bookData: IBooksModal) => {
          bookData.AllocatedTo = bookData.AllocatedTo ? bookData.AllocatedTo : 1;
        });
        // setBooksDetail(response.data);
        setAllBookDetails(response.data);
      }
    })
  }

  const getTopBooks = () => {
    BookService.getTopBooks(2).then((response: any) => {
      if (response && response.data && response.data.length > 0) {
        console.log(response.data);
        response.data.forEach((bookData: IBooksModal) => {
          bookData.AllocatedTo = bookData.AllocatedTo ? bookData.AllocatedTo : 1;
        });
        setBooksDetail(response.data);
        setTopBooksDetail(response.data);
      }
    })
  }

  const getTopBooksByDepartment = (topBooks: number, departmentCode: string) => {
    BookService.getTopBooksByDepartment(topBooks, departmentCode).then((response: any) => {
      if (response && response.data && response.data.length > 0) {
        console.log(response.data);
        response.data.forEach((bookData: IBooksModal) => {
          bookData.AllocatedTo = bookData.AllocatedTo ? bookData.AllocatedTo : 1;
        });
        setBooksDetail(response.data);
        setTopBooksDetail(response.data);
      }
    })
  }

  const getAllEmployees = () => {
    const param = {
      pageNumber: 1,
      pageSize: 100
    }
    EmployeeService.getAllEmployees(param).then((response: any) => {
      if (response && response.data && response.data.length > 0)
        setEmployeesDetail(response.data);
    })
  }


  const AllocateBook = (data: any) => {
    data.IsAllocated = true;
    BookService.AllocateBook(data).then((response: any) => {
      if (response && response.data && response.data.length > 0) {
        toast.success('Book allocated successfully!');
        getTopBooksByDepartment(100, 'KSN');
      }
    })
  }

  const DeAllocateBook = (data: any) => {
    data.IsAllocated = null;
    data.AllocatedTo = null;
    BookService.AllocateBook(data).then((response: any) => {
      if (response && response.data && response.data.length > 0) {
        toast.success('Book collected successfully!');
        getTopBooksByDepartment(100, 'KSN');
      }
    })
  }

  const DeleteBook = (data?: number) => {
    BookService.DeleteBook(data).then((response: any) => {
      if (response && response.data && response.data.length > 0)
        toast.success('Book deleted successfully!');
    })
  }

  const AllocateBookHandler = (data: any) => {
    AllocateBook(data);
  }

  const DeAllocateBookHandler = (data: any) => {
    AllocateBook(data);
  }

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("UserDetails") || '');
    const param = {
      pageNumber: 1,
      pageSize: 0,
      departmentCode: userDetails.departmentCode
    }
    getAllBooksByDepartment(param);
    getTopBooksByDepartment(100, userDetails.departmentCode);
    getAllEmployees();
  }, []);

  const AllocateClick = (event: any, data: IBooksModal) => {
    AllocateBook(data);
  }

  const DeAllocateClick = (event: any, data: IBooksModal) => {
    DeAllocateBook(data);
  }

  const handleClickOpen = (e: any, data: any) => {
    setSelectedBookSerialNo(data);
    e.stopPropagation(); // don't select this row after clicking
    //console.log(params.row)
    setOpen(true);
    //const api: GridApi = params.api;
  };

  const handleClosePositive = (event: any) => {
    console.log(event);
    DeleteBook(event);
    setOpen(false);
  };

  const handleCloseNegative = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateBookDetails = (e: any, data: IBooksModal) => {
    e.preventDefault();
    console.log(data);
    props.setBookDetails(data);
  }

  const handleOnChange = (event: any, value: any) => {
    const userDetails = JSON.parse(localStorage.getItem("UserDetails") || '');
    event.preventDefault();
    setSearchValue(value);
    if (searchOn === 'Bookcode') {
      searchOnCode(value,userDetails.departmentCode);
    } else if (searchOn === 'Bookname') {
      searchOnName(value,userDetails.departmentCode);
    } else if (searchOn === 'Bookauthor') {
      searchOnAuthor(value,userDetails.departmentCode);
    }
  }

  const searchOnCode = (value: any,departmentCode:string) => {
    BookService.GetBookByCode(value,departmentCode).then((response: any) => {
      if (response.data !== null) {
        console.log(response.data);
        setBooksDetail(response.data);
      }
    }).catch((error: any) => {
      console.log(error);
    })
  }
  const searchOnName = (value: any,departmentCode:string) => {
    BookService.GetBookByName(value,departmentCode).then((response: any) => {
      if (response.data !== null) {
        console.log(response.data);
        setBooksDetail(response.data);
      }
    }).catch((error: any) => {
      console.log(error);
    })
  }

  const searchOnAuthor = (value: any,departmentCode:string) => {
    BookService.GetBookByAuthor(value,departmentCode).then((response: any) => {
      if (response.data !== null) {
        console.log(response.data);
        setBooksDetail(response.data);
      }
    }).catch((error: any) => {
      console.log(error);
    })
  }

  const checkIfEmpty = (e: any) => {
    if (e) {
      setSearchTerm((e.target.value).toString());
      setSearchValue((e.target.value).toString());
    }
    setBooksDetail(topBooksDetails);
  }

  const changeSearchOn = (value: any) => {
    setSearchOn(value);
    if (searchTerm !== null && searchTerm.length > 0) {
      setSearchTerm('');
      setSearchValue('');
    }
  }
  return (
    <Container fluid>
      <Form >
        <Grid columns={1}>
          <Grid.Column>

            <Segment basic>

              <Grid className="pt20">
                <Grid.Column floated='left' width={6}>
                  <Header as='h2'  >Books</Header>
                </Grid.Column>
                <Grid.Column floated='right' width={4}>

                  <Form.Group widths='equal'>
                    <Form.Field className='col-form'>
                      <select className='form-select' name="booksFieldDropdown" onChange={(event: any) => changeSearchOn(event.target.value)} >
                        <option value={'Bookcode'}>Book Code</option>
                        <option value={'Bookname'}>Book Name</option>
                        <option value={'Bookauthor'}>Book Author</option>
                      </select>
                    </Form.Field>
                    {/* <Form.Field className='col-form'> */}
                    <Autocomplete size="small"
                      // freeSolo
                      id="free-solo-2-demo"
                      disableClearable
                      className="custom-autocomplete"
                      options={(searchTerm !== null && searchTerm.length >= 2) ?
                        Array.from(new Set(allBookDetails.map(item => item[searchOn as ObjectKey]))).sort((a: any, b: any) => a.toUpperCase().localeCompare(b.toUpperCase()))
                        : []}
                      // getOptionLabel={(option:any) => option[searchOn as ObjectKey]}
                      filterOptions={filterOptions}
                      onInputChange={checkIfEmpty}
                      value={searchValue}
                      onChange={(event, value) => handleOnChange(event, value)}
                      renderInput={(params) =>
                        <TextField {...params} label="Search input"
                          InputProps={{ ...params.InputProps, type: 'search' }} />
                      }
                    />
                    {/* </Form.Field> */}
                  </Form.Group>

                </Grid.Column>
                <Grid.Column textAlign='right' width={2}>
                  <Button
                    floated='right'
                    icon
                    labelPosition='left'
                    primary
                    fluid
                    size='small'
                    onClick={() => addBookCalled('addbook')}>
                    <Icon name='book' /> Add Book
                  </Button>
                </Grid.Column>
              </Grid>
              <Card.Group itemsPerRow={5} >
                {booksDetail.map((data: IBooksModal, index: number) =>
                  <Card key={data.Bookserialno} color={data.IsAllocated ? 'red' : 'green'}>
                    <Card.Content>

                      <Card.Header className="headername">{data.Bookname} <span className="closeicon">
                        <Icon onClick={(e: any) => updateBookDetails(e, data)} name='edit' />
                        <Icon onClick={(e: any) => handleClickOpen(e, data.Bookserialno)} name='close' />
                      </span></Card.Header>
                      <Card.Meta className="sm">By {data.Bookauthor}<span className="code">{data.Bookcode}</span></Card.Meta>
                      <Card.Meta>  <span className="year">Year - {data.Bookyear}</span></Card.Meta>
                      <Card.Description className="edition">
                        <span className="code">Volume : {data.Bookvolume}</span>
                        Book Edition - {data.Edition}
                      </Card.Description>
                    </Card.Content>
                    <Card.Content>
                      <Feed>
                        <Feed.Content>
                          Remarks 1 : {data.Remark1}
                        </Feed.Content>
                        <Feed.Content>
                          Remarks 2 : {data.DepartmentCode}
                        </Feed.Content>
                        <Feed.Content>
                          Remarks 3 : {data.Remark3}
                        </Feed.Content>
                      </Feed>
                    </Card.Content>
                    <Card.Content extra>
                      <Card.Description>
                        <Form.Field className='col-form'>
                          <select className='form-select' value={data?.AllocatedTo?.toString()} name="AllocatedTo"
                            onChange={(event: any) => handleFieldData(event.target.value, event.target.name, index)}>
                            {employeeDetail.map((emp: any) =>
                              <option value={emp.ID.toString()} key={emp.ID}>{emp.DisplayName}</option>
                            )}
                          </select>
                        </Form.Field>
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      <div className='ui two buttons'>
                        <Button basic color='green' onClick={(event: any) => AllocateClick(event.target.value, data)} disabled={data.IsAllocated ? true : false}>
                          Allocate
                        </Button>
                        <Button basic color='red' onClick={(event: any) => DeAllocateClick(event.target.value, data)} disabled={data.IsAllocated ? false : true}>
                          Deallocate
                        </Button>
                      </div>
                    </Card.Content>
                  </Card>
                )}

              </Card.Group>
            </Segment>
          </Grid.Column>
        </Grid>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete Your Book"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this Book
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button negative onClick={handleCloseNegative}>No</Button>
            <Button positive onClick={(event: any) => handleClosePositive(bookserialNo)} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Form>
    </Container>
  );
}

export default Books;
