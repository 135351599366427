import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { DataGrid, GridColDef, GridValueGetterParams, GridApi, GridRenderCellParams, useGridApiContext, GridToolbar, gridClasses } from '@mui/x-data-grid';
import {
  Grid,
  Segment,
  Header,
  Container,
  Icon, Button, Input,
} from 'semantic-ui-react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Select, { SelectChangeEvent } from '@mui/material/Select';

import Chip from '@mui/material/Chip';
import { green, grey } from "@mui/material/colors";
import * as BIIcons from 'react-icons/bi';
import EmployeeService from "../../services/employeeServices";
import { ToastContainer, toast } from "react-toastify";


function SelectEditInputCell(props: GridRenderCellParams) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (event: SelectChangeEvent) => {
    await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      size="small"
      sx={{ height: 1 }}
      native
      autoFocus
    >
      <option>Finance</option>
      <option>HR</option>
      <option>Tech</option>
      <option>Admin</option>
      <option>Ops</option>
      <option>Business</option>
      <option>IT</option>
      <option>Support</option>
    </Select>
  );
}
const renderSelectEditInputCell: GridColDef['renderCell'] = (params) => {
  return <SelectEditInputCell {...params.row.role} />;
};

function Employees(props: any) {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 15,
    page: 0,
  });
  const [previousPgSize, setPreviousPgSize] = useState(paginationModel.pageSize)
  const [data, setData] = useState({
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [5, 10, 15]
  });
  const updateData = (k: string, v: any) => setData((prev) => ({ ...prev, [k]: v }));


  useEffect(() => {
    if (previousPgSize !== paginationModel.pageSize) {
      setPaginationModel({
        ...paginationModel,
        page: 0
      })
      setPreviousPgSize(paginationModel.pageSize);
    }
    getAllEmployees();
  }, [paginationModel.page, paginationModel.pageSize]);

  const [open, setOpen] = React.useState(false);
  const [employeeID, deleteEmployeeID] = useState(0);
  const [employeeData, setEmployeeData] = useState([]);

  const columns: GridColDef[] = [
    { field: 'ID', headerName: 'ID', width: 50, },
    { field: 'EmployeeCode', headerName: 'Employee Code', width: 150, flex: 1 },
    { field: 'FirstName', headerName: 'First name', width: 150, flex: 1 },
    { field: 'LastName', headerName: 'Last name', width: 180, flex: 1 },
    { field: 'BirthDate', headerName: 'BirthDate', type: 'date', width: 150, flex: 1 },
    { field: 'Salary', headerName: 'Salary', type: 'number', width: 150, flex: 1 },
    { field: 'Education', headerName: 'Education', width: 150, flex: 1 },
    { field: 'GraduationYear', headerName: 'Graduation Year', width: 150, flex: 1 },
    {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.FirstName || ''} ${params.row.LastName || ''}`,
    },
    {
      field: 'inout',
      headerName: 'In/Out',
      type: 'number',
      width: 150,
      sortable: false,
      align: "right",
      editable: true,
      renderEditCell: renderSelectEditInputCell,
      renderCell: (params) => {

        // const renderSelectEditInputCell: GridColDef['renderCell'] = (params) => {
        //   return <SelectEditInputCell {...params} />;
        // };
        const getChipProps = (e: any) => {
          if (params.row.status === true) {
            return {
              icon: <BIIcons.BiLogIn style={{ fill: green[500] }} />,

              style: {
                borderColor: green[500]
              }
            };
          } else {
            return {
              icon: <BIIcons.BiLogOut style={{ fill: grey[400] }} />,

              style: {
                borderColor: grey[400]
              }
            };
          }
        }
        return <div>{params.row.role}<Chip className="cstChip" {...getChipProps(params)} /></div>;
      }

    },
    {
      field: 'action',
      type: 'actions',
      headerName: 'Action',
      width: 200,
      cellClassName: 'actions',

      renderCell: (params) => {

        const handleClickOpen = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking
          console.log(params.row)
          deleteEmployeeID(params.row.ID)
          setOpen(true);
          const api: GridApi = params.api;
        };

        const handleEdit = (e: any) => {
          e.preventDefault();
          console.log(params.row);
          props.setOriginalData(params.row);
        }
        return (
          <div>
            <Button.Group>
              {/* <Button icon='add' basic color='blue' /> */}
              <Button icon='edit' basic color='blue' onClick={handleEdit} />
              <Button icon='trash' basic color='red' onClick={handleClickOpen} />
            </Button.Group>

          </div>
        );
      }
    },
  ];


  const [activeItem, setActive] = useState('')
  const navigate = useNavigate()
  useEffect(() => {
    if (activeItem) {
      props.setOriginalData(null);
      navigate(`/${activeItem}`)
    }
  }, [activeItem])

  const handleClose = (event: any) => {
    if (event.target.innerText === 'Yes') {
      EmployeeService.deleteEmployee(employeeID).then((res: any) => {
        if (res.data !== 0) {
          toast.success('Employee deleted successfully!');
          getAllEmployees();
        } else {
          toast.error('Something went wrong!');
        }
        setOpen(false);
      }).catch(() => {
        toast.error('Something went wrong!');
      })
    } else {
      setOpen(false);
    }
  };

  const getAllEmployees = () => {
    const param = {
      pageNumber: paginationModel.page + 1,
      pageSize: paginationModel.pageSize
    }
    EmployeeService.getAllEmployees(param).then((response: any) => {
      if (response.data && response.data.length > 0) {
        response.data.forEach((x: any) => {
          x.BirthDate = new Date(new Date(x.BirthDate).toString() + ' UTC')
        });
        setEmployeeData(response.data);
        const rows = response.data
        console.log(rows);
        updateData("rows", rows);
        updateData("totalRows", response.data[0].Total);
      }
    })
  }
  return (
    <Container fluid>
      <Grid columns={1}>
        <Grid.Column>

          <Grid className="pt20">
            <Grid.Column floated='left' width={6}>
              <Header as='h2'  >Employees</Header>
            </Grid.Column>
            <Grid.Column textAlign='right' floated='right' width={6}>
              <Button
                floated='right'
                icon
                labelPosition='left'
                primary
                size='small'
                onClick={() => setActive('addemployees')}
              >
                <Icon name='user' /> Add Employees
              </Button>
            </Grid.Column>
          </Grid>




          {/* <Grid  >
              <Grid.Column textAlign='right' >
                <Input action={{ icon: 'search' }} placeholder='Search...' />
              </Grid.Column>

            </Grid> */}

          <div style={{ height: 590, width: '100%', marginTop: '20px' }}>
            <DataGrid
              getRowHeight={() => 'auto'}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 1,
                },
              }}
              slots={{ toolbar: GridToolbar }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              className="customInput"
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              disableRowSelectionOnClick
              rows={data.rows}
              rowCount={data.totalRows}
              getRowId={(r) => r.ID}
              columns={columns}
              pageSizeOptions={data.rowsPerPageOptions}
              paginationModel={paginationModel}
              paginationMode="server"
              onPaginationModelChange={setPaginationModel} />
          </div>




        </Grid.Column>
      </Grid>

      <Dialog
        open={open}
        onClick={(event: any) => handleClose(event)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Your Account"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete your account
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button negative onClick={(event: any) => handleClose(event)}>No</Button>
          <Button positive onClick={(event: any) => handleClose(event)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    </Container>



  );
}
export default Employees;
