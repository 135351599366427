import React from 'react';
import Logo from "../ui-component/Logo";
import Topmenu from "../ui-component/topmenu";
import Topheader from "../ui-component/TopHeader";
import * as FaIcons from "react-icons/fa";

import {
    Grid,
    Segment,
    Header,
    Container,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

function Dashboard() {
    const [visible, setVisible] = React.useState(false)
    return (


        <Container fluid>
            <Grid columns={1}>
                <Grid.Column>
                    <Segment basic>
                        <Header as='h3'>Dashboard</Header>

                    </Segment>
                </Grid.Column>
            </Grid>
        </Container>
    );
}

export default Dashboard;
