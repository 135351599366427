import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { DataGrid, GridColDef, GridValueGetterParams, GridApi, GridRenderCellParams, useGridApiContext, GridToolbar, gridClasses } from '@mui/x-data-grid';
import {
  Grid,
  Segment,
  Header,
  Container,
  Icon, Button, Input,
} from 'semantic-ui-react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Select, { SelectChangeEvent } from '@mui/material/Select';

import Chip from '@mui/material/Chip';
import { green, grey } from "@mui/material/colors";
import * as BIIcons from 'react-icons/bi';
import BookService from "../../services/bookServices";
import { ToastContainer, toast } from "react-toastify";

function BooksReport() {

  const [open, setOpen] = React.useState(false);
  const [bookSerialNo, deleteBook] = useState(0);
  const [bookData, setBookData] = useState([]);

  const columns: GridColDef[] = [
    { field: 'Bookserialno', headerName: 'Id', width: 80, },
    { field: 'Bookcode', headerName: 'Code', width: 110 },
    { field: 'Bookname', headerName: 'Title', width: 200 },
    { field: 'Bookauthor', headerName: 'Author', width: 200 },
    { field: 'Bookyear', headerName: 'Year', width: 80 },
    { field: 'Bookvolume', headerName: 'Volume', width: 80 },
    { field: 'Edition', headerName: 'Edition', width: 80 },
    { field: 'Remark1', headerName: 'Remarks 1', width: 100 },
    { field: 'EmployeeName', headerName: 'Allocated To', width: 100 }
  ];


  const [activeItem, setActive] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    if (activeItem) {
      // props.setOriginalData(null);
      navigate(`/${activeItem}`)
    }
  }, [activeItem])

  const handleClose = (event: any) => {
    if (event.target.innerText === 'Yes') {
      BookService.DeleteBook(bookSerialNo).then((res: any) => {
        if (res.data !== 0) {
          toast.success('Book deleted successfully!');
          getAllBooks();
        } else {
          toast.error('Something went wrong!');
        }
        setOpen(false);
      }).catch(() => {
        toast.error('Something went wrong!');
      })
    } else {
      setOpen(false);
    }
  };

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("UserDetails") || '');
    const param = {
      pageNumber: 1,
      pageSize: 100,
      departmentCode: userDetails.departmentCode
    }
    getAllBooksWithAllocationByDepartment(param.departmentCode);
  }, [])

  const getAllBooks = () => {
    BookService.getAllBooks().then((response: any) => {
      if (response.data && response.data.length > 0) {
        response.data.forEach((x: any) => {
          console.log('loop through records');
        });

        setBookData(response.data);
      }
    })
  }
  const getAllBooksWithAllocationByDepartment = (param: any) => {
    BookService.getAllBooksWithAllocationByDepartment(param).then((response: any) => {
      if (response.data && response.data.length > 0) {
        response.data.forEach((x: any) => {
          console.log('loop through records');
        });

        setBookData(response.data);
      }
    })
  }
  return (
    <Container fluid>
      <Grid columns={1}>
        <Grid.Column>

          <Grid className="pt20">
            <Grid.Column floated='left' width={6}>
              <Header as='h2'  >Books List</Header>
            </Grid.Column>
            <Grid.Column textAlign='right' floated='right' width={6}>
              {/* <Button
                floated='right'
                icon
                labelPosition='left'
                primary
                size='small'
                onClick={() => setActive('AddBook')}
              >
                <Icon name='print' /> Print
              </Button> */}
            </Grid.Column>
          </Grid>




          {/* <Grid  >
              <Grid.Column textAlign='right' >
                <Input action={{ icon: 'search' }} placeholder='Search...' />
              </Grid.Column>

            </Grid> */}

          <div style={{ height: 590, width: '100%', marginTop: '20px' }}>
            <DataGrid
              getRowHeight={() => 'auto'}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 1,
                },
                '@media print': {
                  '.MuiDataGrid-main': { color: 'rgba(0, 0, 0, 0.87)' },
                },
              }}
              rows={bookData}
              columns={columns}
              getRowId={(r) => r.Bookserialno}
              slots={{ toolbar: GridToolbar }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              className="customInput"
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                  printOptions:{
                    pageStyle: '.MuiDataGrid-root .MuiDataGrid-main { color: rgba(0, 0, 0, 0.87); }'}
                },
              }}
              // initialState={{
              //   pagination: {
              //     paginationModel: { pageSize: 10, page: 0 },
              //   },
              // }}
            />
          </div>




        </Grid.Column>
      </Grid>

      {/* <Dialog
        open={open}
        onClick={(event: any) => handleClose(event)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Your Account"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete your account
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button negative onClick={(event: any) => handleClose(event)}>No</Button>
          <Button positive onClick={(event: any) => handleClose(event)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog> */}

    </Container>



  );
}
export default BooksReport;
