import { useState } from 'react';
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import Dashboard from '../Components/Dashboard';
import Navbar from '../Components/Nav/Navbar';
import Users from '../Components/Users/Users';
import Employees from '../Components/Employees/Employees';
import Departments from '../Components/Departments/Departments';
import AddDepartment from '../Components/Departments/AddDapartment';
import AddUser from '../Components/Users/AddUser';
import AddEmployee from '../Components/Employees/AddEmployee';
import Books from '../Components/books/books';
import AddBook from '../Components/books/AddBook';
import BooksList from '../Components/books/BooksList';
import MyProfile from '../Components/myprofile/myprofile';
import BooksReport from '../Components/books/BooksReport';
import ProfileEdit from '../Components/myprofile/profileEdit';

interface IEmployeeProps {
  EmployeeCode: string,
  DisplayName: string,
  DepartmentCode: string,
  FirstName: string,
  LastName: string,
  BirthDate: string,
  Salary: number,
  Education: string,
  GraduationYear: number,
  IsDeleted: false
}

interface IDepartmentForm {
  ID?: number;
  Code: string;
  Name: string;
  IsDelete?: boolean;
}

function BlankLayout() {
  const [userToUpdate, setUserDataToUpdate] = useState({});
  const [dataToUpdate, setDataToUpdate] = useState({} as IEmployeeProps);
  const [departmentToUpdate, setDepartmentToUpdate] = useState({} as IDepartmentForm);
  const [BookToUpdate, setBookToUpdate] = useState({});

  const navigate = useNavigate();
  function setOriginalUserData(data: any) {
    console.log("Data", data);
    setUserDataToUpdate(data);
    navigate(`/adduser`)
  }
  function setOriginalData(data: IEmployeeProps) {
    console.log("Data", data);
    setDataToUpdate(data);
    navigate(`/addemployees`)
  }

  function setDepartmentData(data: IDepartmentForm) {
    console.log("Data", data);
    setDepartmentToUpdate(data);
    navigate(`/adddepartments`)
  }

  function setBookDetails(data: any) {
    console.log("Data", data);
    setBookToUpdate(data);
    navigate(`/addbook`)
  }

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/users" element={<Users setOriginalUserData={setOriginalUserData} />} />
        <Route path="/employees" element={<Employees setOriginalData={setOriginalData} />} />
        <Route path="/departments" element={<Departments setDepartmentData={setDepartmentData} />} />
        <Route path="/adddepartments" element={<AddDepartment departmentDetails={departmentToUpdate} />} />
        <Route path="/adduser" element={<AddUser userDetails={userToUpdate} />} />
        <Route path="/addemployees" element={<AddEmployee employeeDetails={dataToUpdate} />} />
        <Route path="/books" element={<Books setBookDetails={setBookDetails} />} />
        <Route path="/bookslist" element={<BooksList />} />
        <Route path="/BooksReport" element={<BooksReport />} />
        <Route path="/addbook" element={<AddBook bookDetails={BookToUpdate} />} />
        <Route path="/myprofile" element={<MyProfile />} />
        <Route path="/profileEdit" element={<ProfileEdit />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </>
  );
}

export default BlankLayout;
