import React, { Fragment, useState } from "react";
import { Form, Container, Header, Segment } from "semantic-ui-react";

function Signup() {
    return (
        <Container className="mt20">
            <Segment.Group>
                <Segment basic>
                    <Header as='h3'  >Sign Up</Header>
                </Segment>
                <Segment>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Field className='col-form'>
                                <label className='label'>Employed ID</label>
                                <input className='form-control'
                                    type='text' name="Employedid"
                                    placeholder='Employed ID' />
                            </Form.Field>
                            <Form.Field className='col-form'>
                                <label className='label'>Display Name</label>
                                <input className='form-control'
                                    type='text' name="DisplayName"
                                    placeholder='Display Name' />
                            </Form.Field>
                            <Form.Field className='col-form'>
                                <label className='label'>Department</label>
                                <select className='form-select'>
                                    <option>Select 1</option>
                                    <option>Select 2</option>
                                    <option>Select 3</option>
                                </select>
                            </Form.Field>
                        </Form.Group>

                        <Form.Group widths="equal">
                            <Form.Field className='col-form'>
                                <label className='label'>First name</label>
                                <input className='form-control'
                                    type='text' name="Firstname"
                                    placeholder='First name' />
                            </Form.Field>
                            <Form.Field className='col-form'>
                                <label className='label'>Last name</label>
                                <input className='form-control'
                                    type='text' name="lastName"
                                    placeholder='Last name' />
                            </Form.Field>
                            <Form.Field className='col-form'>
                                <label className='label'>Birth Date</label>
                                <input className='form-control'
                                    type='date' name="lastName"
                                    placeholder='Last name' />
                            </Form.Field>
                        </Form.Group>

                        <Form.Group widths="equal">
                            <Form.Field className='col-form'>
                                <label className='label'>Salary</label>
                                <input type="range" min="1" max="100" value="50" className='form-control' id="myRange" />
                            </Form.Field>

                            <Form.Field className='col-form'>
                                <label className='label'>Education</label>
                                <input className='form-control'
                                    type='text' name="education"
                                    placeholder='Education' />
                            </Form.Field>

                            <Form.Field className='col-form'>
                                <label className='label'>Grauduation Year</label>
                                <input className='form-control'
                                    type='date' name="education"
                                    placeholder='Education' />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                        </Form.Group>
                        <Form.Group inline>
                            <Form.Button primary>Submit</Form.Button>
                            <Form.Button  >Reset</Form.Button>
                        </Form.Group>
                    </Form>
                </Segment>
            </Segment.Group>
        </Container>
    );
};

export default Signup;
