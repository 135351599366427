import { FormEvent, useEffect, useState } from 'react';
import { Button, Form, Header, Message } from 'semantic-ui-react';
import { Link, useNavigate } from "react-router-dom";
import Logo from '../ui-component/Logo';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserService from '../services/userservices';
interface IUserForm {
  UserId?: number;
  UserName: string;
  Password: string;
  IsDelete?: boolean;
  EmployeeCode?: string;
}

const defaultUserFormValue =
{
  UserName: '',
  Password: ''
}


function Login(props: { setUserDetails: (Data: any) => void }) {

  useEffect(() => {
    localStorage.removeItem('UserDetails');
    localStorage.setItem('IsLoggedIn', JSON.stringify(false));
  }, []);


  const navigate = useNavigate()
  const [form, setForm] = useState<IUserForm>(defaultUserFormValue);
  const [formErrors, setFormErrors] = useState<any>({});

  const OnChangUserName = (event: any) => {
    setForm({
      ...form,
      UserName: event.target.value,
    });
    if (event.target.value.trim() !== '') {
      setFormErrors({ ...formErrors, UserName: '' })
    } else {
      setFormErrors({ ...formErrors, UserName: 'UserName is required!' })
    }
  };

  const OnChangePassword = (event: any) => {
    setForm({
      ...form,
      Password: event.target.value,
    });
    if ((event.target.value.trim()).length === 0) {
      setFormErrors({ ...formErrors, Password: 'Password is required' });
    } else if (event.target.value.length < 4) {
      setFormErrors({ ...formErrors, Password: 'Password must be more than 4 characters' });
    } else if (event.target.value.length > 10) {
      setFormErrors({ ...formErrors, Password: 'Password cannot exceed more than 10 characters' });
    } else {
      setFormErrors({ ...formErrors, Password: '' })
    }
  };

  const onSubmitForm = () => {
    setFormErrors(validate(form));
    if (Object.keys(validate(form)).length === 0) {
      console.log('form', form);
      UserService.AuthenticateUser(form).then((response: any) => {
        console.log(response.data);
        if (response.data !== null && response.data !== undefined && response.data.userId !== 0) {
          toast.success('Login Succesfully');
          props.setUserDetails(response.data);
          setTimeout(() => {
            navigate('/dashboard');
          }, 2000);
        }
        else {
          toast.error('User name/Password is Invalid!');
        }
      }).catch(() => {
        toast.error('User name/Password is Invalid!');
      })
    }
  }

  const validate = (values: any) => {
    const errors: any = {};
    if (!values.UserName) {
      errors.UserName = 'UserName is required!';
    }
    if (!values.Password) {
      errors.Password = 'Password is required';
    } else if (values.Password.length < 4) {
      errors.Password = 'Password must be more than 4 characters';
    } else if (values.Password.length > 10) {
      errors.Password = 'Password cannot exceed more than 10 characters';
    }
    return errors;
  };


  return (
    <div className='main'>
      <Form className="create-form">
        <Header as='h1' color='teal' textAlign='center'>
        <Logo logoWidth={110} />
        </Header>
        {/* <Form.Field className='col-form'>
          <label className='label'>Email</label>
          <input className='form-control'
            type='email'
            value={email}
            //onChange={handleEmail}
            onChange={(e) => { setEmail(e.target.value) }}
            placeholder='Email' />
        </Form.Field> */}
        <Form.Field className='col-form'>
          <label className='label'>User name</label>
          <input className='form-control'
            type='text'
            value={form.UserName}
            onChange={OnChangUserName}
            onBlur={OnChangUserName}
            placeholder='User name' required />
          <p>{formErrors?.UserName}</p>
        </Form.Field>
        <Form.Field className='col-form'>
          <label className='label'>Password</label>
          <input className='form-control' type='Password'
            value={form.Password}
            onChange={OnChangePassword}
            onBlur={OnChangePassword}
            placeholder='Password' required />
          <p>{formErrors?.Password}</p>
        </Form.Field>
        {/* <Form.Field className='col-form'>
          <label className='label'>Password</label>
          <input className='form-control' type='Password'
            value={Password}
            //onChange={handlePassword}
            onChange={(e) => { setPassword(e.target.value) }}
            placeholder='Password' />
        </Form.Field> */}
        <div className='col-form'>
          {/* <Button type='submit' onClick={handleApi} primary className='ui fluid animated  button' >
            <div className="visible content">
              Login
            </div>
            <div className="hidden content"><i aria-hidden="true" className="arrow right icon"></i></div>
          </Button> */}
          <Button type='submit' onClick={onSubmitForm} primary className='ui fluid animated  button' >
            <div className="visible content">
              Login
            </div>
            <div className="hidden content"><i aria-hidden="true" className="arrow right icon"></i></div>
          </Button>
        </div>
        <Message>
          New to us? <Link to="/signup">Sign Up</Link>
        </Message>
      </Form>
    </div>

  );
}

export default Login;
