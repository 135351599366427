import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, Container, Grid, Header, Icon, Segment } from "semantic-ui-react";
import DepartmentService from "../../services/departmentServices";
import EmployeeService from "../../services/employeeServices";

interface IEmployeeProps {
  EmployeeCode: string,
  DisplayName: string,
  DepartmentCode: string,
  FirstName: string,
  LastName: string,
  BirthDate: string,
  Salary: number,
  Education: string,
  GraduationYear: number,
  IsDeleted: false
}

interface Iprops {
  employeeDetails: IEmployeeProps
}
function AddEmployees(props: Iprops) {
  const defaultEmployeeData: IEmployeeProps = {
    EmployeeCode: '',
    DisplayName: '',
    DepartmentCode: 'IT',
    FirstName: '',
    LastName: '',
    BirthDate: '',
    Salary: 10,
    Education: '',
    GraduationYear: 2000,
    IsDeleted: false
  }
  const navigate = useNavigate();

  const [departments, setDepartment] = useState([]);
  const [employeeForm, setEmployeeFrom] = useState(defaultEmployeeData);

  const { register, formState: { errors }, handleSubmit, getValues, setValue } = useForm<IEmployeeProps>({ defaultValues: employeeForm });
  const [isUpdateFlag, isUpdate] = useState(false);
  useEffect(() => {
    console.log(props.employeeDetails);
    if (props.employeeDetails !== null && Object.keys(props.employeeDetails).length > 0) {
      props.employeeDetails.BirthDate = new Date(props.employeeDetails.BirthDate).toISOString().split('T')[0]
      setEmployeeFrom(props.employeeDetails);
      Object.entries(props.employeeDetails).forEach(entry => {
        const [key, value]: any = entry;
        setValue(key, value)
      });
      isUpdate(true);
    }
    getAllDepartments();
  }, [props.employeeDetails]);

  const getAllDepartments = () => {
    const param = {
      pageSize: 100,
      pageNumber: 1
    };
    DepartmentService.getAllDepartments(param).then((response: any) => {
      if (response && response.data && response.data.length > 0)
        setDepartment(response.data);
      console.log(response.data);
    })
  }


  // function handleFieldData(value: any, name: string) {
  //   setEmployeeFrom({ ...employeeForm, [name]: value });
  //   console.log({ ...employeeForm });
  // }

  const addUpdateEmployeeData = (data: any) => {
    //event.preventDefault();
    //setEmployeeFrom(data)
    console.log(getValues());
    if (isUpdateFlag) {
      updateEmployeeData()
    } else {
      addEmployeeData()
    }

  }

  const addEmployeeData = () => {
    EmployeeService.createEmployee(getValues()).then((response: any) => {
      console.log(response.data);
      if (response.data && response.data > 0) {
        toast.success('Employee added successfully!');
        setTimeout(() => {
          navigate('/employees');
        }, 2000);
      } else {
        toast.error('Something went wrong!');
      }
    }).catch(() => {
      toast.error('Something went wrong!');
    })
  }

  const updateEmployeeData = () => {
    EmployeeService.updateEmployee(getValues()).then((response: any) => {
      console.log(response.data);
      if (response.data && response.data > 0) {
        toast.success('Employee Updated successfully!');
        setTimeout(() => {
          navigate('/employees');
        }, 2000);
      } else {
        toast.error('Something went wrong!');
      }
    }).catch(() => {
      toast.error('Something went wrong!');
    })
  }

  return (
    <Container>
      <Grid columns={1}>
        <Grid.Column>

          <Grid className="pt20">
            <Grid.Column width={6}>
              <div className="vmid">


                <Link to={"/employees"} className="backbtn">
                  <Icon name='arrow left' />
                </Link>
                <Header as='h2' className="mt0 pl15"  >Add Employees</Header>
              </div>

            </Grid.Column>
            <Grid.Column textAlign='right' floated='right'  >

            </Grid.Column>
          </Grid>

        </Grid.Column>
        <Grid columns={1}>
          <Segment>
            <Form onSubmit={handleSubmit(addUpdateEmployeeData)}>
              <Form.Group widths="equal">
                <Form.Field className='col-form'>
                  <label className='label'>Employed Code</label>
                  <input className='form-control'
                    type='text' disabled={isUpdateFlag && employeeForm.EmployeeCode.length > 0 ? true : false}
                    //value={employeeForm?.EmployeeCode}
                    //onChange={(event: any) => handleFieldData(event.target.value, event.target.name)}
                    {...register('EmployeeCode', { required: true })}
                    placeholder='Employed Code' />
                  {errors.EmployeeCode && <p role='alert'>Employee Code is required</p>}
                </Form.Field>
                <Form.Field className='col-form'>
                  <label className='label'>Display Name</label>
                  <input className='form-control'
                    type='text'// name="DisplayName"
                    //value={employeeForm?.DisplayName}
                    //onChange={(event: any) => handleFieldData(event.target.value, event.target.name)}
                    {...register('DisplayName', { required: true })}
                    placeholder='Display Name' />
                  {errors.DisplayName && <p role='alert'>Display Name is required</p>}
                </Form.Field>
                <Form.Field className='col-form'>
                  <label className='label'>Department</label>
                  <select className='form-select' //value={employeeForm?.DepartmentCode} name="DepartmentCode"
                    //onChange={(event: any) => handleFieldData(event.target.value, event.target.name)}
                    {...register('DepartmentCode', { required: true })}
                  >
                    {departments.map((data: any) =>
                      <option value={data.Code} key={data.ID} selected={data.Code === getValues('DepartmentCode')}>{data.Name}</option>
                    )}
                  </select>
                  {errors.DepartmentCode && <p role='alert'>Department Code is required</p>}
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field className='col-form'>
                  <label className='label'>First name</label>
                  <input className='form-control'
                    type='text' //name="FirstName"
                    // value={employeeForm?.FirstName}
                    // onChange={(event: any) => handleFieldData(event.target.value, event.target.name)}
                    {...register('FirstName', { required: true })}
                    placeholder='First name' />
                  {errors.FirstName && <p role='alert'>First name is required</p>}
                </Form.Field>
                <Form.Field className='col-form'>
                  <label className='label'>Last name</label>
                  <input className='form-control'
                    type='text' //name="LastName"
                    //value={employeeForm?.LastName}
                    {...register('LastName', { required: true })}
                    //onChange={(event: any) => handleFieldData(event.target.value, event.target.name)}
                    placeholder='Last name' />
                  {errors.LastName && <p role='alert'>Last Name is required</p>}
                </Form.Field>
                <Form.Field className='col-form'>
                  <label className='label'>Birth Date</label>
                  <input className='form-control'
                    type='date' //name="BirthDate"
                    //value={employeeForm?.BirthDate}
                    {...register('BirthDate', { required: true })}
                  //onChange={(event: any) => handleFieldData(event.target.value, event.target.name)}
                  />
                  {errors.BirthDate && <p role='alert'>BirthDate is required</p>}
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field className='col-form'>
                  <label className='label'>Salary</label>
                  <input type="range" className='form-control' //name="Salary"
                    //onChange={(event: any) => handleFieldData(event.target.value, event.target.name)}
                    //value={employeeForm?.Salary}
                    {...register('Salary', { required: true, min: 1, max: 100 })}
                    id="myRange" />
                  {errors.Salary && <p role='alert'>Salary is required</p>}
                </Form.Field>

                <Form.Field className='col-form'>
                  <label className='label'>Education</label>
                  <input className='form-control'
                    type='text' //name="Education"
                    // value={employeeForm?.Education}
                    //onChange={(event: any) => handleFieldData(event.target.value, event.target.name)}
                    {...register('Education', { required: true })}
                    placeholder='Education' />
                  {errors.Education && <p role='alert'>Education is required</p>}
                </Form.Field>

                <Form.Field className='col-form'>
                  <label className='label'>Graduation Year</label>
                  <input className='form-control'
                    type='number' min='1900' max='2099'
                    // name="GraduationYear"
                    // value={employeeForm?.GraduationYear}
                    // onChange={(event: any) => handleFieldData(event.target.value, event.target.name)}
                    {...register('GraduationYear', { required: true, min: 1900, max: 2099 })}
                    placeholder='GraduationYear' />
                  {errors.GraduationYear && <p role='alert'>Graduation Year is required</p>}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
              </Form.Group>
              <Form.Group inline>
                {/* <Form.Button primary onClick={($event: any) => addUpdateEmployeeData($event)}> */}
                <Form.Button primary type="submit">
                  Submit
                </Form.Button>
                <Form.Button onClick={() => setEmployeeFrom(defaultEmployeeData)} >Reset</Form.Button>
              </Form.Group>
            </Form>
          </Segment>
        </Grid>
      </Grid>
    </Container>
  );
}
export default AddEmployees;
