import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
    Button,
    Card,
    // Grid,
    // Segment,
    // Header,
    Container, Grid, Header, Icon, Image, Segment, Table, Form, Feed, List
    // Button,
    // Icon,
    // Card, Form, Input, Feed, Search
} from 'semantic-ui-react';
import EmployeeService from "../../services/employeeServices";
import UserService from "../../services/userservices";
import ProfileImg from "../../ui-component/profileImg";




function ProfileEdit() {
    const navigate = useNavigate();
    const defaultUserValues = {
        FirstName: '',
        LastName: '',
        Address: '',
        Email: '',
        PhoneNumber: '',
        EmployeeCode: ''
    }
    const [userDetails, setUserDetails] = useState(defaultUserValues);
    useEffect(() => {
        setUserDetails(JSON.parse(localStorage.getItem("ProfileData") || ''));
    }, [])

    function handleFieldData(value: any, name: string) {
        setUserDetails({ ...userDetails, [name]: value });
        console.log({ ...userDetails });
    }

    const editProfile = (e: any) => {
        e.preventDefault();
        EmployeeService.updateEmployeeProfile(userDetails).then((response: any) => {
            localStorage.removeItem('ProfileData');
            if (response && response.data) {
                toast.success('Profile updated successfully!');
                navigate('/myprofile');
            } else {
                toast.error('Something went wrong!');
            }
        }).catch((error: any) => {
            toast.error('Something went wrong!');
            console.log(error);
        })
    }
    const [fileData, setFileData] = useState<any>();
    const [fileName, setFileName] = useState();
    const [imageBinaryData, setImageBinaryData] = useState();
    const [url, setURL] = useState<any>();
    const selectImage = (fileInput: any) => {
        if (fileInput && fileInput.target.files[0]) {
            setFileData(fileInput.target.files[0]);
            setFileName(fileInput.target.files[0].name);
            preview(fileInput.target.files[0]);
        }
    }

    const preview = (filedata: any) => {
        let mimeType = filedata?.type;
        if (mimeType?.match(/image\/*/) == null) {
            toast.error('Invalid File Format');
            return;
        }
        let reader = new FileReader();
        reader.readAsDataURL(filedata);
        reader.onload = (_event) => {
            if (reader.result)
                setURL(reader.result);
            const ImageBinaryData = (reader.result)?.toString().replace('data:' + filedata?.mimeType + ';base64,', '');
            //setImageBinaryData(ImageBinaryData?.split(/,(.+)/)[1]);
            uploadFile(filedata,ImageBinaryData);
        }
    }

    const uploadFile = (filedata: any, ImageBinaryData: any) => {
        const param = {
          FileName: filedata.name,
          ImageBinaryData: ImageBinaryData
        };
        UserService.UploadFile(param).then((res:any)=>{
            console.log(res.data);
        })
      }

    return (
        <Container className="pt40">
            <Grid columns={1}>
                <Grid.Column>
                    <Grid className="">
                        <Grid.Column width={6}>
                            <div className="vmid">


                                <Link to={"/myprofile"} className="backbtn">
                                    <Icon name='arrow left' />
                                </Link>
                                <Header as='h2' className="mt0 pl15"  >Edit Profile</Header>
                            </div>

                        </Grid.Column>
                        <Grid.Column textAlign='right' floated='right'  >

                        </Grid.Column>
                    </Grid>
                </Grid.Column>

                <Grid columns={1}>
                    <Segment>
                        <Form>

                            <Form.Group widths='equal'>
                                <Form.Field className='col-form '>
                                    <List.Item className="uploadItem">
                                        <ProfileImg widthImg={70} />
                                        <List.Content className="f1">
                                            <label className='label'>Profile Image</label>
                                            <input className='form-control upload-file'
                                                type='file' placeholder='User Name'
                                                name="image" accept="image/*" onChange={selectImage} />


                                        </List.Content>
                                        <List.Content style={{ marginTop: 15 + 'px', marginLeft: 5 + 'px' }}  >
                                            <Button
                                                color='blue'
                                                icon='upload'
                                                className="mt5"
                                                size='small'
                                            />
                                            <Button
                                                color='red'
                                                icon='delete'
                                                className="mt5"
                                                size='small'
                                            />
                                        </List.Content>

                                    </List.Item>
                                </Form.Field>
                                <Form.Field className='col-form '>

                                </Form.Field>

                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Field className='col-form '>
                                    <label className='label'>First Name</label>
                                    <input className='form-control upload-file'
                                        value={userDetails?.FirstName}
                                        onChange={(event: any) => handleFieldData(event.target.value, event.target.name)}
                                        type='text' name="Firstname" placeholder='User Name' />
                                </Form.Field>
                                <Form.Field className='col-form '>
                                    <label className='label'>Last Name</label>
                                    <input className='form-control upload-file'
                                        value={userDetails?.LastName}
                                        onChange={(event: any) => handleFieldData(event.target.value, event.target.name)}
                                        type='text' name="Lastname" placeholder='User Name' />
                                </Form.Field>
                                <Form.Field className='col-form '>
                                    <label className='label'>Email</label>
                                    <input className='form-control upload-file'
                                        value={userDetails?.Email}
                                        onChange={(event: any) => handleFieldData(event.target.value, event.target.name)}
                                        type='email' name="Email" placeholder='Email' />
                                </Form.Field>

                            </Form.Group>

                            <Form.Group widths='equal'>


                                <Form.Field className='col-form '>
                                    <label className='label'>Mobile</label>
                                    <input className='form-control upload-file'
                                        value={userDetails?.PhoneNumber}
                                        onChange={(event: any) => handleFieldData(event.target.value, event.target.name)}
                                        type='text' name="PhoneNumber" placeholder='Mobile' />
                                </Form.Field>
                                <Form.Field className='col-form '>
                                    <label className='label'>Address</label>
                                    <input className='form-control upload-file'
                                        value={userDetails?.Address}
                                        onChange={(event: any) => handleFieldData(event.target.value, event.target.name)}
                                        type='text' name="Address" placeholder='Address' />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group inline>
                                <Form.Button primary onClick={editProfile}>Submit</Form.Button>
                                <Form.Button onClick={() => setUserDetails(defaultUserValues)} >Reset</Form.Button>
                            </Form.Group>
                        </Form>
                    </Segment>
                </Grid>

            </Grid>
        </Container>
    );
}

export default ProfileEdit;
