import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, Button, Container, Grid, Header, Icon, Segment } from "semantic-ui-react";

import UserService from "../../services/userservices";
import EmployeeService from "../../services/employeeServices";

interface IUserProps {
  UserId?: number,
  UserName: string,
  Password: string,
  EmployeeCode?: string,
  IsDeleted: boolean
}
// interface Iprops {
//   userDetails: IUserProps
// }

function AddUser(props: any) {
  const [activeItem, setActive] = useState('')
  const navigate = useNavigate()
  const [isUpdateFlag, isUpdate] = useState(false);
  useEffect(() => {
    console.log(props.userDetails);
    if (props.userDetails !== null && Object.keys(props.userDetails).length > 0) {
      //props.userDetails.BirthDate = new Date(props.userDetails.BirthDate).toISOString().split('T')[0]
      props.userDetails.cPassword = props.userDetails.Password;
      setUserForm(props.userDetails);
      isUpdate(true);
    }
    // getAllDepartments();
  }, [props.userDetails]);

  const getAllEmployees = () => {
    const param = {
      pageNumber: 1,
      pageSize: 100
    }
    EmployeeService.getAllEmployees(param).then((response: any) => {
      if (response && response.data && response.data.length > 0)
        setEmployees(response.data);
      console.log(response.data);
    })
  }

  const defaultUserData = {
    UserId: 0,
    UserName: '',
    Password: '',
    cPassword: '',
    EmployeeCode: '',
    IsDeleted: false
  }

  const [employees, setEmployees] = useState([]);
  const [userForm, setUserForm] = useState(defaultUserData);

  function handleFieldData(value: any, name: string) {
    setUserForm({ ...userForm, [name]: value });
    console.log({ ...userForm });
  }

  const addUpdateUserData = (event: any) => {
    event.preventDefault();
    console.log({ ...userForm });
    if (isUpdateFlag) {
      UpdateUser()
    } else {
      addUserData()
    }

  }

  const UpdateUser = () => {
    UserService.UpdateUser({ ...userForm }).then((response: any) => {
      console.log(response.data);
      if (response.data && response.data > 0) {
        toast.success('User added successfully!');
        setTimeout(() => {
          navigate('/users');
        }, 2000);
      } else {
        toast.error('Something went wrong!');
      }
    }).catch(() => {
      toast.error('Something went wrong!');
    })
  }

  const addUserData = () => {
    UserService.CreateUser({ ...userForm }).then((response: any) => {
      console.log(response.data);
      if (response.data && response.data > 0) {
        toast.success('User added successfully!');
        setTimeout(() => {
          navigate('/users');
        }, 2000);
      } else {
        toast.error('Something went wrong!');
      }
    }).catch(() => {
      toast.error('Something went wrong!');
    })
  }

  useEffect(() => {
    if (activeItem) {
      navigate(`/${activeItem}`)
    }
    getAllEmployees();
  }, [activeItem])

  return (
    <Container>
      <Grid columns={1}>
        <Grid.Column>

          <Grid className="pt20">
            <Grid.Column width={6}>
              <div className="vmid">


                <Link to={"/users"} className="backbtn">
                  <Icon name='arrow left' />
                </Link>
                <Header as='h2' className="mt0 pl15"  >Add Users</Header>
              </div>

            </Grid.Column>
            <Grid.Column textAlign='right' floated='right'  >

            </Grid.Column>
          </Grid>

        </Grid.Column>
        <Grid columns={1}>
          <Segment>
            <Form>
              <Form.Group widths="equal">
                <Form.Field className='col-form'>
                  <label className='label'>User Name</label>
                  <input className='form-control'
                    type='text' name="UserName" onChange={(event: any) => handleFieldData(event.target.value, event.target.name)}
                    placeholder='User Name' value={userForm?.UserName} />
                </Form.Field>
                <Form.Field className='col-form'>
                  <label className='label'>Employee Name</label>
                  <select className='form-select' name="EmployeeCode" value={userForm?.EmployeeCode} onChange={(event: any) => handleFieldData(event.target.value, event.target.name)}>
                  {employees.map((data: any) =>
                      <option value={data.EmployeeCode} key={data.ID}>{data.DisplayName}</option>
                    )}
                  </select>
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field className='col-form'>
                  <label className='label'>Password</label>
                  <input className='form-control'
                    type='password' name="Password"
                    placeholder='Password' 
                    onChange={(event: any) => handleFieldData(event.target.value, event.target.name)}
                    value={userForm?.Password}  />
                </Form.Field>
                <Form.Field className='col-form'>
                  <label className='label'>Confirm Password</label>
                  <input className='form-control'
                    type='password' name="cPassword" 
                    onChange={(event: any) => handleFieldData(event.target.value, event.target.name)} 
                    placeholder='Confirm Password' value={userForm?.cPassword} />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
              </Form.Group>
              <Form.Group inline>
                <Form.Button primary onClick={($event: any) => addUpdateUserData($event)}>Submit</Form.Button>
                <Form.Button  >Reset</Form.Button>
              </Form.Group>
            </Form>
          </Segment>
        </Grid>
      </Grid>
    </Container>
  );
}
export default AddUser;
