import http from "../common/http-common";

const getAll = () => {
  return http.get("/users");
};

const get = (id: number) => {
  return http.get(`/users/${id}`);
};

const CreateUser = (data: any) => {
  return http.post("/Users/CreateUser", data);
};

const UpdateUser = (data: any) => {
  return http.post("/Users/UpdateUser", data);
};

const DeleteUser = (id: number) => {
  return http.post("/Users/DeleteUser", id);
};

const GetUserByUserId = (id: number) => {
  return http.get(`/Users/GetUserByUserId/${id}`);
};

const GetAllUsers = (data: any) => {
  return http.post(`/Users/GetAllUsers`, data);
};

const AuthenticateUser = (param: any) => {
  return http.post(`/Users/AuthenticateUser`, param);
};
const UploadFile = (param: any) => {
  return http.post(`/Document/UploadFile`, param);
};

const ChangePassword = (param: any) => {
  return http.post(`/Users/ChangePassword`, param);
}
const UserService = {
  getAll,
  get,
  CreateUser,
  UpdateUser,
  DeleteUser,
  GetUserByUserId,
  GetAllUsers,
  AuthenticateUser,
  UploadFile,
  ChangePassword
};

export default UserService;

