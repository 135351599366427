import { Navigate } from 'react-router-dom'

interface Iprops {
    children: any
}

function AuthGuard(props: Iprops) {
    const IsLoggedIn = (localStorage.getItem('IsLoggedIn') === 'true');
    console.log("app", IsLoggedIn);

    if (!IsLoggedIn) {
        return <Navigate to="/" replace />;
    }
    return props.children;
}

export default AuthGuard
