import http from "../common/http-common";

const getAllBooks = () => {
  return http.get("/Books/GetAllBooks");
};

const getAllBooksByDepartment = (data:string) => {
  return http.post(`/Books/GetAllBooksByDepartment`,data);
};

const getAllBooksWithAllocationByDepartment = (departmentCode:string) => {
  return http.get(`/Books/GetAllBooksWithAllocationByDepartment?departmentCode=${departmentCode}`);
};

const getTopBooks = (topRecords:any) => {
  return http.get(`/Books/GetTopBooks?topBooks=${topRecords}`);
};

const getTopBooksByDepartment = (topRecords:any,departmentCode:string) => {
  return http.get(`/Books/GetTopBooksByDepartment?topRecords=${topRecords}&departmentCode=${departmentCode}`);
};

const CreateBook = (data:any) => {
  return http.post("/Books/CreateBook",data);
};

const UpdateBook = (data:any) => {
  return http.post("/Books/UpdateBook",data);
};

const AllocateBook = (data:any) => {
  return http.post("/Books/AllocateBook",data);
};

const DeleteBook = (bookId?:number) => {
  return http.post("/Books/DeleteBook",bookId);
};

const GetBookList = (data:any) => {
  return http.get("/Books/GetBookList",data);
};

const GetBookByCode = (data:string,departmentCode:string) => {
  data = data.replace('#','%23');
  return http.get(`/Books/GetBookByCode?bookCode=${data}&departmentCode=${departmentCode}`);
};

const GetBookByName = (data:any,departmentCode:string) => {
  return http.get(`/Books/GetBookByName?bookName=${data}&departmentCode=${departmentCode}`);
};

const GetBookByAuthor = (data:any,departmentCode:string) => {
  return http.get(`/Books/GetBookByAuthor?bookAuthor=${data}&departmentCode=${departmentCode}`);
};

const GetBookCodes = (data:any) => {
  return http.get("/Books/GetBookCodes",data);
};

const GetBookAuthors = (data:any) => {
  return http.get("/Books/GetBookAuthors",data);
};

const GetBookNames = (data:any) => {
  return http.get("/Books/GetBookNames",data);
};

const BookService = {
    getAllBooks,
    getAllBooksByDepartment,
    CreateBook,
    UpdateBook,
    DeleteBook,
    GetBookList,
    AllocateBook,
    GetBookByCode,
    GetBookByName,
    GetBookByAuthor,
    GetBookCodes,
    GetBookAuthors,
    GetBookNames,
    getTopBooks,
    getTopBooksByDepartment,
    getAllBooksWithAllocationByDepartment
}

export default BookService;
