// material-ui

import logo from '../images/nn-logo.png';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = (props) => {


    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */
         <img src={logo} alt="Nanavati & Nanavati" style={{width: props.logoWidth + 'px', height:58 + 'px'}} />
    );
};

export default Logo;
