import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import './Navbar.css';
import { IconContext } from 'react-icons';
import Logo from '../../ui-component/Logo';
import Topmenu from "../../ui-component/topmenu";
import { Grid } from 'semantic-ui-react'
function Navbar() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <IconContext.Provider value={{ color: '#23bcbc' }}>
        <Grid className='navbar' columns={2} verticalAlign='middle' centered >
          <Grid.Column  width={2} className='pb0 vmid' >
            <Link to='#' className='menu-bars'>
              <FaIcons.FaBars onClick={showSidebar} />
            </Link>
            <Logo logoWidth={90} />
          </Grid.Column>
          <Grid.Column textAlign='right' style={{paddingRight: 2 + 'em', marginTop: 0 + 'em'}} verticalAlign='middle' width={14} className='pb0'>
            <Topmenu />
          </Grid.Column>

        </Grid>

        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items' onClick={showSidebar}>
            <li className='navbar-toggle'>
              <Link to='#' className='menu-bars'>
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
