import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from './Authentication/Login';
import BlankLayout from './Layout/blankLayout';
import Signup from './Authentication/Signup';
import { ToastContainer } from 'react-toastify';
import AuthGuard from './Authentication/AuthGuard';
import { useEffect, useState } from 'react';

function App() {

  // const [title, setTitle] = useState("Default Title");
  const setUserDetails = (data: any) => {
    console.log(data)

    localStorage.setItem('UserDetails', JSON.stringify(data));
    localStorage.setItem('IsLoggedIn', JSON.stringify(true));
  }
  // useEffect(() => {
  //   // This will run when the page first loads and whenever the title changes
  //   document.title = title;
  // }, [title]);
  console.log(process.env.REACT_APP_NAME);
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login setUserDetails={setUserDetails} />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/*" element={
            <AuthGuard >
              <BlankLayout />
            </AuthGuard>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
