import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Header, Icon, Menu } from 'semantic-ui-react'
import ProfileImg from './profileImg'


function TopMenu() {
  const [activeItem, setActive] = useState('')
  const navigate = useNavigate()

  const [username, setUserName] = useState();

  useEffect(() => {
    if (activeItem) {
      navigate(`/${activeItem}`)
    }
  }, [activeItem]);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("UserDetails") || '');
    setUserName(userDetails.userName);
  }, []);

  const onLogOut = (e: any) => {
    e.preventDefault();
    localStorage.clear();
    localStorage.setItem('IsLoggedIn', JSON.stringify(false));
    setActive('login');
  }

  return (
    <Menu text >
      <Menu.Item
        name='Users'
        active={activeItem === 'users'}
        onClick={() => setActive('users')}
      />

      <Menu.Item
        name='Employees'
        active={activeItem === 'employees'}
        onClick={() => setActive('employees')}
      />
      <Menu.Item
        name='Departments'
        active={activeItem === 'departments'}
        onClick={() => setActive('departments')}
      />
      <Menu.Item
        name='Books'
        active={activeItem === 'books'}
        onClick={() => setActive('books')}
      />
      <Menu.Item
        name='Books List'
        active={activeItem === 'BooksList'}
        onClick={() => setActive('BooksList')}
      />
      <Menu.Item
        name='Books Report'
        active={activeItem === 'BooksReport'}
        onClick={() => setActive('BooksReport')}
      />
      <Menu.Item position='right' className='pr20' 
      active={activeItem === 'myProfile'}
        onClick={() => setActive('myProfile')}>
          <ProfileImg widthImg={50} />
        <Header as='h4' className='m0' primary>Welcome {username}</Header>
      </Menu.Item>
      
      {/* <Menu.Item position='right' className='pr20'>
        <Header as='h4' primary>Welcome {username}</Header>
      </Menu.Item> */}
      <Menu.Item
        name='Logout'
        active={activeItem === 'Login'}
        onClick={onLogOut}
      >
        <Icon name='log out' size='large' />
      </Menu.Item>

    </Menu>
  )

}

export default TopMenu
// export default class MenuExampleSecondary extends Component {
//   state = { activeItem: 'Dashboard' }

//   handleItemClick = (e, { name }) => this.setState({ activeItem: name })


//   render() {
//     const { activeItem } = this.state

//     return
//   }
// }
