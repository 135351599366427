import http from "../common/http-common";

const getAllDepartments = (data: any) => {
  return http.post("/Departments/GetAllDepartments", data);
};

const CreateDepartment = (data:any) => {
  return http.post("/Departments/CreateDepartment", data);
};

const updateDepartment = (data:any) => {
  return http.post("/Departments/UpdateDepartment", data);
};

const getDepartmentList = (data:any) => {
  return http.get("/Departments/GetDepartmentList", data);
};

const deleteDepartment = (departmentCode:string) => {
  return http.post("/Departments/DeleteDepartment", departmentCode);
};

const DepartmentService = {
    getAllDepartments,
    CreateDepartment,
    getDepartmentList,
    deleteDepartment,
    updateDepartment
}


export default DepartmentService;
