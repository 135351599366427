
const FormValidation = (values: any, property?: any) => {
    const errors: any = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    // if (property) {
    //     if (!values[property as keyof any]) {
    //         errors.username = { property } + ' is required!';
    //     }

    //     if (property === 'email' && !values.email) {
    //         errors.email = 'Email is required!';
    //     } else if (!regex.test(values.email)) {
    //         errors.email = 'This is not a valid email format!';
    //     }
    //     if (property === 'password' && !values.password) {
    //         errors.password = 'Password is required';
    //     } else if (values.password.length < 4) {
    //         errors.password = 'Password must be more than 4 characters';
    //     } else if (values.password.length > 10) {
    //         errors.password = 'Password cannot exceed more than 10 characters';
    //     }
    // }
    // else {
    let newPassword: any;
    Object.entries(values).forEach(entry => {
        const [key, value]: any = entry;
        console.log(key, value);
        if (!value) {
            errors[key] = ' is required!';
        } else {
            if (key === 'newPassword') {
                newPassword = value
            }
            if (key === 'confirmPassword') {
                if (newPassword !== value)
                    errors[key] = ' does not match';
            }
            if (key === 'email' && !value) {
                errors.email = 'Email is required!';
            } else if (key === 'email' && !regex.test(value)) {
                errors.email = 'This is not a valid email format!';
            }
        }
    });
    // }
    return errors;
}

export default FormValidation